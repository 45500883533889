import React, { Component } from 'react';
import { Container, Card, Row, Col, Modal, Button, Table, Badge, Spinner } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { setCalendarPreview } from '../../../redux/slices/calendarPreviewModalSlice';
import moment from 'moment';
import { GET } from '../../../api';
import { toast } from 'react-toastify';

class CalendarPreviewModal extends Component {

  state = {
    fetching: false,
    show: false,
    data: [],
    events: [],
  }

  componentDidUpdate(prevProps) {

    if (prevProps.previewCalendarModal !== this.props.previewCalendarModal && this.props.previewCalendarModal) {

      let data = [];

      if (Array.isArray(this.props.previewCalendarData)) {
        data = this.props.previewCalendarData;
      } else {
        data.push(this.props.previewCalendarData);
      }

      this.setState({ data }, () => this.getCalendar(data[0], data[data.length - 1]));

    }
  }

  handleOpen = (type) => {
    this.props.setCalendarPreview({ showModal: true, type });
  }

  handleClose = () => {
    this.props.setCalendarPreview({ showModal: false, type: this.props.previewCalendarType });
  }

  getCalendar = async (dateStart, dateEnd) => {

    this.setState({ fetching: true });

    const { class_id } = this.props.selectedClass.class;

    let request_params = {
      class_id,
      startDate: moment(dateStart).format('YYYY-MM-DD') + ' 00:00:00',
      endDate: moment(dateEnd).format('YYYY-MM-DD') + ' 23:59:59',
    };

    try {
      const res = await GET('center-calendar/get-center-calendar', {
        params: {
          ...request_params
        }
      });

      this.setState({
        events: res.data,
        fetching: false
      });

    } catch (err) {
      console.log('err', err)
      toast.error('Something went wrong while fetching calendar!');
      this.setState({ fetching: false });
    }
  };

  fetchDateData = (date) => {
    return this.state.events.filter(item => item.date == date);
  }

  renderRow = (row, index) => {
    console.log('renderRow', row, index);


    const type = row.add_type.replace(/_/g, " ").toUpperCase();

    return (
      <div key={index} className='my-2 ps-1'>
        <Badge pill
          style={{
            fontSize: 10,
            marginRight: 4
          }}
          bg={'warning'}>{type}</Badge>
        {row.acs_name &&
          <Badge pill
            style={{
              fontSize: 10
            }}
            bg={'primary'}>{row.acs_name}</Badge>
        }
        <br />
        <strong>{row.title}</strong>
      </div>
    );

  }

  render() {
    console.log('THIS>STATE', this.state);

    return (
      <Modal show={this.props.previewCalendarModal}
        size={this.props.previewCalendarType == 'day' ? 'md' : 'xxl'}
        onHide={this.handleClose}>
        <Modal.Header closeButton style={{
          borderBottom: 0
        }}>
          <Modal.Title>
            {this.props.previewCalendarType == 'day' && t('Day Preview')}
            {this.props.previewCalendarType == 'week' && t('Week Preview')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-0'>
          <Table className='mb-0' size="sm" bordered>
            <thead>
              <tr>
                {this.state.data.map((date, index) => (
                  <th key={index} style={{
                    backgroundColor: 'rgb(247 247 247)',
                  }}>
                    <Badge bg="info me-2">
                      {moment(date).format('DD-MM-YYYY')}
                    </Badge>
                    {moment(date).format('dddd').toUpperCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {this.state.fetching &&
                  <td colSpan={this.state.data.length} style={{ textAlign: 'center' }}>
                    <Spinner size="sm" animation="grow" /> {t('Fetching Data')}
                  </td>
                }
                {!this.state.fetching && this.state.events.length == 0 &&
                  <td colSpan={this.state.data.length} style={{ textAlign: 'center' }}>
                    {t('No Data Found')}
                  </td>
                }
                {!this.state.fetching && this.state.events.length > 0 && this.state.data.map((date, index) => (
                  <td key={index}>{this.fetchDateData(date).map((row, row_index) => this.renderRow(row, row_index))}</td>
                ))}
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  previewCalendarModal: state.calendarPreviewModal.showModal,
  previewCalendarType: state.calendarPreviewModal.type,
  previewCalendarData: state.calendarPreviewModal.data,
});

const mapDispatchToProps = () => ({
  setCalendarPreview,
});

export default connect(mapStateToProps, mapDispatchToProps())(CalendarPreviewModal);
