import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Button, Offcanvas, Modal, Form, Spinner, Tabs, Tab, Badge, Alert } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { TagsInput } from 'react-tag-input-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faCaretDown, faCheck, faCircleCheck, faEdit, faFilePdf, faMicrophone, faPencil, faPlus, faSquare, faSquareCheck, faTimes, faTimesCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle, faCircleDot, faComments } from '@fortawesome/free-regular-svg-icons';
import pegboard from '../../assets/images/pegboard-bg.png';

import { brand_colors } from '../../helpers/brand_colors_helper';

import pdfAttachment from '../../assets/images/pdf.png';
import audioAttachment from '../../assets/images/audio.png';
import imgDescription from '../../assets/images/description.png';

import { getUrlParam, trimThisDown } from '../../helpers/general_helpers';
import { ReactMediaRecorder } from 'react-media-recorder';
import SweetAlert from 'react-bootstrap-sweetalert';
import { handleFormErrors } from '../../helpers/form_helpers';
import Paginate from '../_partials/Paginate/_Paginate';
import { GET, POST } from '../../api';
import GeneralCard from '../../helpers/GeneralCard';
var Chance = require('chance');
var chance = new Chance();


// API URL
const apiURL = process.env.REACT_APP_API_URL;
const controller = 'academic-activities';
const portalURL = process.env.REACT_APP_PORTAL_URL;
const imgDir = 'academic_activities';

/**
 * Activities Component
 */
class Activities extends Component {
  constructor(props) {
    super(props);

    this.formSections = [
      {
        uid: '1',
        active: true,
        completed: false,
        name: 'Activity',
        desc: null,
        data: [
          {
            uid: '1.1',
            active: false,
            completed: false,
            name: 'Name',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.2',
            active: false,
            completed: false,
            name: 'Central Idea',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.3',
            active: false,
            completed: false,
            name: 'Activity Design',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.4',
            active: false,
            completed: false,
            name: 'General Description',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.5',
            active: false,
            completed: false,
            name: 'Unit',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.6',
            active: false,
            completed: false,
            name: 'Image',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.7',
            active: false,
            completed: false,
            name: 'Objectives',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.8',
            active: false,
            completed: false,
            name: 'Attachments',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.9',
            active: false,
            completed: false,
            name: 'Skills',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.10',
            active: false,
            completed: false,
            name: 'External Resources',
            enum: 'external_resource',
            desc: '',
            image: require('../../assets/images/lesson/hyperlink.png'),
            saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'external_resource' && v.editing)) }
          },
        ],
      },
      {
        uid: '2',
        active: false,
        completed: false,
        name: 'IB',
        desc: null,
        data: [
          {
            uid: '2.1',
            active: false,
            completed: false,
            name: 'Transdisciplinary Theme',
            desc: null,
            image: require('../../assets/images/lesson/line-chart.png'),
          },
          {
            uid: '2.2',
            active: false,
            completed: false,
            name: 'Goals & Success Criteria',
            desc: ['What is it we want students to know, understand and be able to do?', 'How are learning goals and success criteria co-constructed between teachers and students?'],
            image: require('../../assets/images/lesson/goal.png'),
          },
          {
            uid: '2.3',
            active: false,
            completed: false,
            name: 'Key Concepts',
            desc: ['It help students to engage in abstract thinking and conceptual thinking to cope-with tricky ideas. Key Concepts can help learners to deal with knowledge in transdisciplinary themes, which the IB intends to encourage. This kind of learning is not limited to factual concepts and content, helping students to engage with broader concepts that can motivate inquisitive, creative and critical thinking.'],
            image: require('../../assets/images/lesson/key-concepts.png'),
          },
          {
            uid: '2.4',
            active: false,
            completed: false,
            name: 'Lines of Enquiry',
            desc: ['What lines of inquiry will define the scope of inquiry into the central idea?'],
            image: require('../../assets/images/lesson/inquiry.png'),
          },
          {
            uid: '2.5',
            sectionGroup: ['All'],
            active: false,
            completed: false,
            name: 'Approaches to learning',
            enum: 'approaches_to_learning',
            image: require('../../assets/images/lesson/learning-approaches.png'),
            desc: ['The Approaches to Learning in PYP include transdisciplinary themes that are important for students learning both inside and outside the classroom. These are the tools that students use across all subject areas to develop into successful students. Students get the opportunity to practice these skills and reflect on how they apply these skills to their everyday learning.'],
          },
          {
            uid: '2.6',
            active: false,
            completed: false,
            name: 'Learner Profile',
            enum: 'learner_profile',
            image: require('../../assets/images/lesson/learner-profile.png'),
            desc: ['The aim of all IB programmes is to develop internationally minded people who, recognizing their common humanity and shared guardianship of the planet, help to create a better and more peaceful world. The IB learner profile represents 10 attributes valued by IB World Schools. We believe these attributes, and others like them, can help individuals and groups become responsible members of local, national and global communities.'],
          },
        ],
      },
      {
        uid: '3',
        active: false,
        completed: false,
        name: 'Assessment',
        desc: null,
        data: [
          {
            uid: '3.1',
            active: false,
            completed: false,
            name: 'Questions',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
        ],
      },
    ]
    this.state = {
      working: false,
      // DATATABLE DATA
      dtable: {
        data: [],
        search: '',
        order: '',
        dir: 'ASC',
        limit: 10,
        offset: 0,
        refreshingData: false,
        finishedData: false,
      },
      // activities: [],
      addModal: { show: false, title: null, id: 0, action: () => { }, allData: [], selectedData: [], onClose: () => this.setState({ addModal: { ...this.state.addModal, show: false } }) },
      // NEW OR EDIT FORM
      academic_activity: {
        aa_id: 0,
        aa_name: '',
        aa_desc: '',
        aa_desc_cidea: '',
        aa_desc_unit: '',
        aa_desc_general: '',
        aa_public: 1,
        curriculum_ids: JSON.stringify([]),
      },
      aa_image: null,
      aa_audio: null,
      academic_activity_attachments: [],
      academic_activity_questions: [],
      academic_activity_resources: [],
      academic_activity_question: {
        aa_q_id: null,
        aa_id: '',
        center_id: '',
        aa_q_desc: '',
        aa_q_order: '',
        aa_q_file: '',
        aa_q_type: '',
        curriculum_ids: JSON.stringify([]),
        aa_q_audio: '',
        academic_activity_question_options: [],
      },
      // academic_activity_resource: {
      //   aar_id: null,
      //   aar_type: '',
      //   aar_type_id: '',
      //   aar_title: '',
      //   aar_desc: '',
      //   aar_url: '',
      //   approved_by: '',
      //   approved_on: '',
      // },
      // DDL OPTIONS
      aa_attach_type: [
        { value: 'document', label: 'Document' },
        { value: 'image', label: 'Image' },
        { value: 'audio', label: 'Audio' },
        { value: 'video', label: 'Video' },
      ],
      aa_q_type: [
        { value: 'MCQ', label: 'Multiple Choice' },
        { value: 'MRQ', label: 'Multiple Response' },
        { value: 'TF', label: 'True False' },
        { value: 'BD', label: 'Brief Description' },
        { value: 'DD', label: 'Detail Description' },
        { value: 'Match', label: 'Matching' },
        { value: 'File', label: 'FIle Upload' },
        { value: 'Blanks', label: 'Fill in the Blanks' },
        { value: 'Audio', label: 'Record Audio' },
      ],
      filterCurriculum: 'ALL', //ALL,LINKED
      curriculums: { curriculum_options_grouped: [], learnarea: [], curriculum_options: [] },
      academicLessonAttributes: [],
      // MODALS
      activityModal: {
        show: false,
        modalTitle: 'New Activity',
        closeModal: () => this.setState({ activityModal: { ...this.state.activityModal, show: false } }),
        toggleModal: () => this.setState({ activityModal: { ...this.state.activityModal, show: !this.state.activityModal.show } }),
        showModal: () => this.setState({ activityModal: { ...this.state.activityModal, show: true } }),
      },
      questionModal: {
        show: false,
        modalTitle: 'New Question',
        closeModal: () => this.setState({ questionModal: { ...this.state.questionModal, show: false } }),
        toggleModal: (academic_activity_question) => this.setState({ questionModal: { ...this.state.questionModal, show: !this.state.questionModal.show }, academic_activity_question }),
      },
      deleteModal: { show: false, id: 0, action: () => { } },
      formSectionActive: {
        parent: '1',
        child: '1.1',
      },
      formSections: this.formSections,
      // Paginate
      limit: 12,
      totalRecords: 0,
      // Assignment Modal
      showAssignmentModal: false,
      selectedActivity: {},
      // Activity Assignment
      activity_assignment: {
        center_id: 0,
        ut_id: 0,
        ut_type: 'Academic Activity',
        class_id: 0,
        user_id: 0,
        center_timezone: null,
        user_timeline: {
          ut_type: 'Academic Activity',
          ut_title: '',
          ut_desc: '',
          ut_attachment_1: '',
          ut_type_table: 'academic_activities',
          ut_type_table_id: 0,
          utc_type: 'Teacher Parent',
          class_ids: [],
          ut_folder: 'academic_activities'
        },
        user_timeline_metadata: {
          start_datetime: '',
          end_datetime: ''
        },
        user_timeline_tagged: [
          {
            child_id: 0,
            parent_id: 0
          }
        ]
      },
      selectedClass: null,
      selectedChildren: null,
      children: [],
      assessmentMenuData: null,
      program: null,
      age: null,
      learnarea: null,
      aspect: null,
      curriculum: null
    };
    this.aa_image_ref = React.createRef();
    this.filePicker = React.createRef();
  }

  componentDidMount() {
    this.getActivities();
    this.getCurriculum();
    this.getLessonAttributes();
    this.getAssessmentMenu();

    let getUrlParamData = getUrlParam()
    if (getUrlParamData.aa_id) {
      this.handleEditForm(getUrlParamData.aa_id)
    }

  }

  // MARK FORM SECTIONS AS COMPLETE AFTER VERIFYING THE REQUIRED FIELDS DATA
  checkCompletedFormSections = () => {
    let formSections = this.state.formSections;
    formSections = formSections.map((v, i) => {
      let data = v.data;
      let sectionComplete = true;
      data = data.map((vv, ii) => {
        if (vv.uid == '1.1') {
          let academic_activity = this.state.academic_activity;
          let subSectionComplete = true;
          if (academic_activity.aa_name) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.2') {
          let academic_activity = this.state.academic_activity;
          let subSectionComplete = true;
          if (academic_activity.aa_desc_cidea) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.3') {
          let academic_activity = this.state.academic_activity;
          let subSectionComplete = true;
          if (academic_activity.aa_desc) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.4') {
          let academic_activity = this.state.academic_activity;
          let subSectionComplete = true;
          if (academic_activity.aa_desc_general) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.5') {
          let academic_activity = this.state.academic_activity;
          let subSectionComplete = true;
          if (academic_activity.aa_desc_unit) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.6') {
          let subSectionComplete = true;
          if (this.state.aa_image) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.7') {
          let academic_activity = this.state.academic_activity;
          let subSectionComplete = true;
          if (JSON.parse(academic_activity.curriculum_ids).length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.8') {
          let academic_activity_attachments = this.state.academic_activity_attachments;
          let subSectionComplete = true;
          if (academic_activity_attachments.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.9') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'skill' && !v.editing);
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.10') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'external_resource' && !v.editing);
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '2.1') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'transdisciplinary_theme_focus');
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '2.2') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'success' && !v.editing);
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        }
        else if (vv.uid == '2.3') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'lesson_key_concept');
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        }
        else if (vv.uid == '2.4') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'lines_of_enquiry');
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        }
        else if (vv.uid == '2.5') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'approaches_to_learning');
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        }
        else if (vv.uid == '2.6') {
          let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_type == 'learner_profile');
          let subSectionComplete = true;
          if (academic_activity_resources.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        }
        else if (vv.uid == '3.1') {
          let academic_activity_questions = this.state.academic_activity_questions;
          let subSectionComplete = true;
          if (academic_activity_questions.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        }
        // else {
        //   sectionComplete = false;
        //   return { ...vv };
        // }

      });
      return { ...v, data, completed: sectionComplete };
    });
    this.setState({ formSections: formSections });
  };
  // CHANGE AND SCROLL TO SECTION
  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        setTimeout(() => {
          const element = document.getElementById('form_section_' + child);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  getActivities = async (reset = false) => {
    if (this.getActivitiesReq) {
      this.getActivitiesReq.abort()
    }
    this.getActivitiesReq = new AbortController()

    let newState = {}
    if (reset) {
      newState = {
        dtable: {
          ...this.state.dtable, data: [],
          search: '',
          order: '',
          dir: 'ASC',
          limit: 10,
          offset: 0,
          refreshingData: true,
          finishedData: false,
        }
      }
    } else {
      newState = {
        dtable: {
          ...this.state.dtable,
          refreshingData: true,
        }
      }
    }
    this.setState(newState, async () => {
      if (this.state.dtable.finishedData) {
        this.setState({
          dtable: {
            ...this.state.dtable,
            refreshingData: false,
          }
        })
        return
      }
      try {
        const res = await GET(controller + '/fetch-table', {
          signal: this.getActivitiesReq.signal,
          params: {
            limit: this.state.dtable.limit,
            offset: this.state.dtable.offset,
            search: this.state.dtable.search,
          },
        });
        let data = [...this.state.dtable.data, ...res.data.data]
        this.setState({
          dtable: {
            ...this.state.dtable,
            data,
            totalRows: res.data.total_count,
            offset: this.state.dtable.limit + this.state.dtable.offset,
            finishedData: (res.data.data.length <= 0) ? true : false,
            refreshingData: false
          }
        })
      } catch (err) {
        console.log('err', err)
        toast.error(t('Something went wrong while fetching activities!'));

      }
    });
  };

  getCurriculum = async () => {
    try {
      const res = await GET(controller + '/get-curriculum');
      if (res) {
        let curriculum_options_grouped = [];
        let curriculum_options = [];
        res.data.map((v, i) => {
          let thisOptions = [];
          v.curriculum.map((vv) => {
            thisOptions.push({ label: vv.curriculum_code, value: vv.curriculum_id, learnarea_index: i });
            curriculum_options.push({ label: vv.curriculum_code, value: vv.curriculum_id, learnarea_index: i });
          });
          curriculum_options_grouped.push({
            label: v.learnarea_desc,
            options: thisOptions,
            learnarea_index: i,
            learnarea_code: v.learnarea_code
          });
        });
        this.setState({
          curriculums: { ...this.state.curriculums, learnarea: res.data, curriculum_options_grouped: curriculum_options_grouped, curriculum_options: curriculum_options },
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching curriculum!'));

    }
  };

  getLessonAttributes = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;

    try {
      const res = await GET('academic-subject-lessons/get-lesson-attributes');

      if (res.status == 200) {
        this.setState({ academicLessonAttributes: res.data });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );

    }
  };

  setStateAcademicActivity = (field, value) => {
    this.setState({ academic_activity: { ...this.state.academic_activity, [field]: value } }, () => this.checkCompletedFormSections());
  };
  setStateAcademicActivityQuestion = (field, value) => {
    this.setState({ academic_activity_question: { ...this.state.academic_activity_question, academic_activity_question_options: field == 'aa_q_type' && value != this.state.academic_activity_question.aa_q_type ? [] : this.state.academic_activity_question.academic_activity_question_options, [field]: value } });
  };

  getAssessmentMenu = () => {
    if (typeof this.getAssessmentMenuRequest != typeof undefined) {
      this.getAssessmentMenuRequest.abort();
    }

    this.getAssessmentMenuRequest = new AbortController();

    if (typeof this.getAllCurriculumRequest != typeof undefined) {
      this.getAllCurriculumRequest.abort();
    }

    this.getAllCurriculumRequest = new AbortController();

    axios
      .all([
        GET('assesslog/get-assessment-menu', {
          signal: this.getAssessmentMenuRequest.signal,
        }),
        GET('assesslog/get-all-curriculum', {
          signal: this.getAllCurriculumRequest.signal,
        }),
      ])
      .then(
        axios.spread((assessmentMenuData, getAllCurriculumData) => {
          this.setState({
            assessmentMenuData: {
              ...assessmentMenuData.data,
              allCurriculum: getAllCurriculumData.data
            }
          });
        }))
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong!'
          );

        }
      });
  }
  /**
   * Preview Upload File
   * @returns
   */
  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
      const allowedFilesDisplay = ['PDF', 'Image'];
      const allowedSize = 5000000;

      if (selectedFile.size) {
        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }

        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };
  addActivityAttachment = (e) => {
    const selectedFiles = e.target.files;
    let allFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }

    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments }, () => this.checkCompletedFormSections());
    });
  };

  setActivityResource = (field, value, aar_type, aar_id) => {
    let academic_activity_resources = this.state.academic_activity_resources;
    let findIndex = academic_activity_resources.findIndex((v, i) => v.aar_type == aar_type && v.aar_id == aar_id);
    if (findIndex != -1) {
      academic_activity_resources[findIndex][field] = value;
    } else {
      academic_activity_resources.push({
        aar_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        aar_type: aar_type,
        aar_type_id: null,
        aar_title: '',
        aar_desc: '',
        aar_url: '',
        aar_public: 1,
        [field]: value,
        editing: true,
      });
    }
    this.setState({ academic_activity_resources });
  };

  deleteAcademicActivity = (data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: 0, action: async () => {
          try {
            const res = await GET(controller + '/delete-academic-activity', {
              params: {
                aa_id: data.aa_id,
              },
            });
            if (res) {
              toast[res.data.messageType](t(res.data.message));
              if (res.data.type) {
                this.getActivities(true)
              }
            }
            this.setState({
              deleteModal: {
                ...this.state.deleteModal, show: false,
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong!'));
            this.setState({
              deleteModal: {
                ...this.state.deleteModal, show: false,
              }
            })
          }
        }
      }
    });
  }

  deleteActivityResources = async () => {
    let academic_activity_resources = this.state.academic_activity_resources.filter((v, i) => v.aar_id != this.state.deleteModal.id);
    this.setState({ academic_activity_resources, deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } }, () => this.checkCompletedFormSections());
  };
  // var context = new window.AudioContext();
  // var source;
  //     playSound = (arraybuffer) => {
  //         context.decodeAudioData(arraybuffer, function (buf) {
  //             source = context.createBufferSource();
  //             source.connect(context.destination);
  //             source.buffer = buf;
  //             source.start(0);
  //         });
  //     }

  addUpdateQuestion = () => {
    const { academic_activity_question } = this.state;
    const academic_activity_question_options = academic_activity_question.academic_activity_question_options;
    // Question type is required
    if (academic_activity_question.aa_q_type == '') {
      toast.error(t('Question type is required!'));
      return;
    }
    if (academic_activity_question.aa_q_desc == '') {
      toast.error(t('Question desc is required!'));
      return;
    }

    // options are less than 2
    if ((academic_activity_question.aa_q_type == 'TF' || academic_activity_question.aa_q_type == 'MCQ' || academic_activity_question.aa_q_type == 'MRQ') && academic_activity_question_options.length < 2) {
      toast.error(t('Please add two options at-least!'));
      return;
    } else if (academic_activity_question.aa_q_type == 'TF' || academic_activity_question.aa_q_type == 'MCQ' || academic_activity_question.aa_q_type == 'MRQ') {
      let findCheckedOption = academic_activity_question_options.find((v, i) => v.aa_q_op_checked);
      if (findCheckedOption == undefined) {
        toast.error(t('At-least on option should be marked as correct!'));
        return;
      }
    }

    academic_activity_question['academic_activity_question_options'] = academic_activity_question_options

    let academic_activity_questions = this.state.academic_activity_questions;
    if (academic_activity_question.aa_q_id == null) {
      academic_activity_question.aa_q_id = chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0];
      academic_activity_questions.push(academic_activity_question);
    } else {
      let findQuestion = academic_activity_questions.findIndex((v, i) => v.aa_q_id == academic_activity_question.aa_q_id);
      academic_activity_questions[findQuestion] = academic_activity_question;
    }
    this.setState(
      {
        academic_activity_questions,
        questionModal: { ...this.state.questionModal, show: false },
      },
      () => this.checkCompletedFormSections()
    );
  };

  editQuestion = (id) => {
    this.setState({ academic_activity_question: this.state.academic_activity_questions[id], questionModal: { ...this.state.questionModal, modalTitle: 'Update Question', show: true } });
  };

  addQuestionOption = () => {
    const { academic_activity_question } = this.state;

    // Don't let user add more than two options
    // if question type is true/false
    if (academic_activity_question.aa_q_type == 'TF' && academic_activity_question.academic_activity_question_options.length === 2) {
      toast.error(t('Only two options can be added for true/false Question!'));
      return;
    }

    const new_academic_activity_question_options = {
      aa_q_op_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
      aa_q_id: academic_activity_question.aa_q_id,
      aa_id: '',
      center_id: '',
      aa_q_op_text: '',
      aa_q_op_text_picture: '',
      aa_q_op_value: '',
      aa_q_op_value_picture: '',
      aa_q_op_checked: 0,
    };

    academic_activity_question.academic_activity_question_options.push(new_academic_activity_question_options);

    this.setState({ academic_activity_question });
  };

  handleOptionFileChange = (index) => (e) => {
    const { academic_activity_question_options } = this.state.academic_activity_question;
    academic_activity_question_options[index][e.target.name] = e.target.files[0];
    this.setState({ academic_activity_question: { ...this.state.academic_activity_question, academic_activity_question_options } });
  };

  handleOptionTextChange = (index) => (e) => {
    const { academic_activity_question_options } = this.state.academic_activity_question;

    academic_activity_question_options[index][e.target.name] = e.target.value;
    this.setState({ academic_activity_question: { ...this.state.academic_activity_question, academic_activity_question_options } });
  };

  deleteOption = (id) => {
    const { academic_activity_question_options } = this.state.academic_activity_question;

    academic_activity_question_options.splice(id, 1);
    this.setState({ academic_activity_question: { ...this.state.academic_activity_question, academic_activity_question_options } });
  };
  handleOptionTagsChange = (index, name) => (e) => {
    const { academic_activity_question_options } = this.state.academic_activity_question;

    academic_activity_question_options[index][name] = e;
    this.setState({ academic_activity_question: { ...this.state.academic_activity_question, academic_activity_question_options } });
  };
  handleOptionCheckChange = (index, state_name, select_single = false) => {
    const { academic_activity_question_options } = this.state.academic_activity_question;

    if (select_single) {
      for (var i = 0; i < academic_activity_question_options.length; i++) {
        academic_activity_question_options[i][state_name] = 0;
      }
    }
    academic_activity_question_options[index][state_name] = academic_activity_question_options[index][state_name] == 0 ? 1 : 0;
    this.setState({ academic_activity_question: { ...this.state.academic_activity_question, academic_activity_question_options } });
  };

  readAudioFileData = async (mediaBlobUrl) => {
    if (!mediaBlobUrl) return null;
    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    return new File([audioBlob], `${chance.guid()}.wav`, { type: 'audio/wav' });
  };

  saveActivity = async () => {
    this.setState({ working: true });
    let { academic_activity, aa_image, academic_activity_attachments, academic_activity_questions, academic_activity_resources } = this.state;
    let aa_audio = await this.readAudioFileData(this.state.aa_audio);

    const { auth_key, user_id } = this.props.authData.loginData;
    const { employee_id } = this.props.authData.userData;
    const { center_id, center_timezone } = this.props.authData.centerData;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    if (aa_audio) {
      fData.append('aa_audio', aa_audio);
    }
    if (aa_image) {
      fData.append('aa_image', aa_image.data);
    }
    fData.append('academic_activity', JSON.stringify(academic_activity));
    fData.append('academic_activity_questions', JSON.stringify(academic_activity_questions));
    fData.append('academic_activity_resources', JSON.stringify(academic_activity_resources));
    academic_activity_attachments.map((v, i) => {
      fData.append('academic_activity_attachments[]', v.data);
    });

    academic_activity_questions.map((v, i) => {
      let aa_q_id = v.aa_q_id;
      v.academic_activity_question_options.map((vv, ii) => {
        if (vv.aa_q_op_value_picture) {
          fData.append('aa_q_op_value_picture[' + ii + '][' + aa_q_id + ']', vv.aa_q_op_value_picture);
        }
        if (vv.aa_q_op_text_picture) {
          fData.append('aa_q_op_text_picture[' + ii + '][' + aa_q_id + ']', vv.aa_q_op_text_picture);
        }
      });
    });

    try {
      if (this.saveActivityReq) {
        this.saveActivityReq.abort();
      }
      this.saveActivityReq = new AbortController();

      const res = await POST(controller + '/save-activity', fData, {
        signal: this.saveActivityReq.signal,
      });

      if (res.data.type) {
        this.setState({
          working: false,
          academic_activity: {
            aa_id: 0,
            aa_name: '',
            aa_desc: '',
            aa_desc_cidea: '',
            aa_desc_unit: '',
            aa_desc_general: '',
            aa_public: 1,
            curriculum_ids: JSON.stringify([]),
          },
          aa_image: null,
          aa_audio: null,
          academic_activity_attachments: [],
          academic_activity_questions: [],
          academic_activity_resources: [],
          academic_activity_question: {
            aa_q_id: null,
            aa_id: '',
            center_id: '',
            aa_q_desc: '',
            aa_q_order: '',
            aa_q_file: '',
            aa_q_type: '',
            curriculum_ids: JSON.stringify([]),
            aa_q_audio: '',
            academic_activity_question_options: [],
          },
          activityModal: { ...this.state.activityModal, show: false },
        });

        this.getActivities(true);
      } else {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t(res.data.message));
        }
        this.setState({
          working: false,
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while saving activity!'));
      this.setState({ working: false });

    }
  };

  handleEditForm = async (aa_id) => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    this.setState({
      isEditing: true,
      activityModal: {
        ...this.state.activityModal,
        show: true,
        modalTitle: 'Edit Activity',
      },
    }, async () => {
      try {
        const res = await GET(controller + '/edit-data', {
          params: {
            aa_id,
          },
        });


        if (res.status == 200) {
          const { aa_id, aa_name, aa_desc, aa_desc_cidea, aa_desc_unit, aa_desc_general, aa_public, curriculum_ids, aa_image, center_id } = res.data.academic_activities;

          const attachments = res.data.academic_activity_attachments.map((item) => {
            return {
              url: portalURL + item.center_id + '/academic_activity_attachments/' + item.aa_attach_name,
              data: {
                type: item.aa_attach_type,
              },
            };
          });


          this.setState({
            aa_image: (aa_image) ? portalURL + center_id + '/academic_activities/' + aa_image : null,
            academic_activity: {
              aa_id,
              aa_name,
              aa_desc,
              aa_desc_cidea,
              aa_desc_unit,
              aa_desc_general,
              aa_public,
              curriculum_ids: curriculum_ids,
            },
            academic_activity_resources: res.data.academic_activity_resources,
            academic_activity_questions: res.data.academic_activity_questions,
            academic_activity_attachments: attachments,
            formSectionActive: {
              parent: '1',
              child: '1.1',
            },
            formSections: this.formSections,
          }, () => {
            this.checkCompletedFormSections()
          });
        }
      } catch (err) {
        console.log('err', err)
        toast.error(t('Something went wrong while fetching total coefficient!'));

      }
    });

  };

  handleAssignmentForm = async (data) => {
    const { showAssignmentModal } = this.state;
    const { authData, selectedClass } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const class_id = selectedClass.class.class_id;

    this.setState({
      showAssignmentModal: !showAssignmentModal,
      selectedActivity: data
    });

  };

  onChangeClass = async (class_obj) => {

    this.setState({ selectedChildren: null });

    const { authData, selectedClass } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const class_id = selectedClass.class.class_id;

    const fData = new FormData();

    fData.append('center_id', center_id);
    fData.append('class_ids', JSON.stringify([class_id]));

    try {
      const res = await axios.post(apiURL + 'children/get-classes-children', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      if (res.status == 200) {
        this.setState({
          selectedClass: class_obj,
          children: res.data.map((item) => {
            return {
              value: item.child_id,
              label: item.child_name
            };
          })
        });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching class children!'));

    }

  }

  setChildren = (children_obj) => {
    this.setState({
      selectedChildren: children_obj
    });
  }

  resetForm = () => {
    this.setState({
      academic_activity: {
        aa_id: 0,
        aa_name: '',
        aa_desc: '',
        aa_desc_cidea: '',
        aa_desc_unit: '',
        aa_desc_general: '',
        aa_public: 1,
        curriculum_ids: JSON.stringify([]),
      },
      aa_image: null,
      aa_audio: null,
      academic_activity_attachments: [],
      academic_activity_questions: [],
      academic_activity_resources: [],
      academic_activity_question: {
        aa_q_id: null,
        aa_id: '',
        center_id: '',
        aa_q_desc: '',
        aa_q_order: '',
        aa_q_file: '',
        aa_q_type: '',
        curriculum_ids: JSON.stringify([]),
        aa_q_audio: '',
        academic_activity_question_options: [],
      },
      // academic_activity_resource: {
      //     aar_id: null,
      //     aar_type: '',
      //     aar_type_id: '',
      //     aar_title: '',
      //     aar_desc: '',
      // },
      // DDL OPTIONS
      aa_attach_type: [
        { value: 'document', label: 'Document' },
        { value: 'image', label: 'Image' },
        { value: 'audio', label: 'Audio' },
        { value: 'video', label: 'Video' },
      ],
      aa_q_type: [
        { value: 'MCQ', label: 'Multiple Choice' },
        { value: 'MRQ', label: 'Multiple Response' },
        { value: 'TF', label: 'True False' },
        { value: 'BD', label: 'Brief Description' },
        { value: 'DD', label: 'Detail Description' },
        { value: 'Match', label: 'Matching' },
        { value: 'File', label: 'FIle Upload' },
        { value: 'Blanks', label: 'Fill in the Blanks' },
        { value: 'Audio', label: 'Record Audio' },
      ],
      filterCurriculum: 'ALL', //ALL,LINKED
    });
  };

  onChangeProgram = (obj) => {
    this.setState({ program: obj });
  };
  onChangeAge = (obj) => {
    this.setState({ age: obj });
  };
  onChangeLearnArea = (obj) => {
    this.setState({ learnarea: obj });
  };
  onChangeAspect = (obj) => {
    this.setState({ aspect: obj });
  };
  render() {
    console.log('ACTIVITY', this.state);
    let { dtable, academic_activity, academic_activity_questions, academic_activity_attachments, activityModal, questionModal, aa_attach_type, aa_q_type, academic_activity_question, curriculums, aa_image, aa_audio, formSectionActive, formSections, limit, totalRecords, working, isEditing, showAssignmentModal, selectedActivity, activity_assignment, selectedChildren, selectedClass, children, program, age, learnarea, aspect, curriculum, assessmentMenuData } = this.state;

    const { defaultTheme } = this.props;

    const editSkill = this.state.academic_activity_resources.find((v, i) => v.aar_type == 'skill' && v.editing);
    const editExternalResource = this.state.academic_activity_resources.find((v, i) => v.aar_type == 'external_resource' && v.editing);
    const editSuccess = this.state.academic_activity_resources.find((v, i) => v.aar_type == 'success' && v.editing);
    const editLinesOfEnquiry = this.state.academic_activity_resources.find((v, i) => v.aar_type == 'lines_of_enquiry' && v.editing);

    const transdisciplinary_theme = this.state.academic_activity_resources.find((v, i) => v.aar_type == 'transdisciplinary_theme')
    const transdisciplinary_theme_focus = this.state.academic_activity_resources.find((v, i) => v.aar_type == 'transdisciplinary_theme_focus');

    let programList = [];
    let ageList = [];
    let learnareaList = [];
    let aspectList = [];
    let curriculumList = [];

    if (assessmentMenuData) {
      programList = assessmentMenuData.progass.map((item) => {
        return {
          label: item.progass_code,
          value: item.progass_id
        }
      });


      if (program) {
        ageList = assessmentMenuData.agegroup.filter((item) => {
          return item.progass_id == program.value;
        }).map((item) => {
          return {
            label: item.agegroup_code,
            value: item.agegroup_id
          }
        });
      }

      if (program) {
        learnareaList = assessmentMenuData.learnarea.filter((item) => {
          return item.progass_id == program.value;
        }).map((item) => {
          return {
            label: item.learnarea_code,
            value: item.learnarea_id
          }
        });
      }

      if (learnarea) {
        aspectList = assessmentMenuData.lrnaspect.filter((item) => {
          return item.learnarea_id == learnarea.value;
        }).map((item) => {
          return {
            label: item.lrnaspect_code,
            value: item.lrnaspect_id
          }
        });
      }

      if (age && aspect) {
        curriculumList = assessmentMenuData.allCurriculum.filter((item) => {
          return item.agegroup_id == age.value && item.lrnaspect_id == aspect.value;
        }).map((item) => {
          return {
            label: item.curriculum_code,
            value: item.curriculum_id
          }
        });
      }

    }


    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Activity Design')} backBtn={true} />
            <div className='grey-section'>
              <Card className='border-0' style={{ borderRadius: '20px' }}>
                <Card.Body>
                  <Row style={{ justifyContent: 'space-between', }}>
                    <Col md={6}>
                      <Button
                        variant='success'
                        onClick={() => {
                          this.setState({
                            activityModal: {
                              ...this.state.activityModal,
                              show: true,
                              modalTitle: 'Create Activity',
                            },
                          });
                          this.resetForm();
                        }}
                      >
                        {t('Create New')}
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Form.Control style={{ marginBottom: 4 }} type='text' placeholder={t('Search...')} value={dtable.search} onChange={(e) => this.setState({ dtable: { ...dtable, search: e.target.value, offset: 0, finishedData: false } }, () => this.getActivities(true))} />
                    </Col>
                  </Row>

                  <Row>
                    {dtable.data.map((v, i) => {
                      let data = {
                        allData: v,
                        name: v.aa_name,
                        text1: t('Last Updated') + ':',
                        text2: moment((v.updated_on) ? v.updated_on : v.updated_on).format('DD-MM-YYYY'),
                        description: v.aa_desc,
                        // linkTo: '/subject/lessons/' + v.acs_name,
                        // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                        cardImage: (v.aa_image) ? portalURL + v.center_id + '/academic_activities/' + v.aa_image : require('../../assets/images/noimage.png'),
                        actions: [
                          { type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.handleEditForm(v.aa_id) },
                          { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => this.deleteAcademicActivity(v) },
                        ]
                      }
                      return <GeneralCard data={data} key={i} />
                    })}

                    <Col md={12} className='d-flex align-items-center justify-content-center'>
                      {(this.state.dtable.data.length <= 0 && this.state.dtable.finishedData) && t('No Activities found!')}

                      {this.state.dtable.refreshingData && (
                        <Spinner size='lg' animation='grow' variant='dark' />

                      )}
                      {(this.state.dtable.data.length > 0 && this.state.dtable.finishedData) && t('No more  Activities found!')}
                    </Col>
                  </Row>
                  {(!this.state.dtable.finishedData && !this.state.dtable.refreshingData) &&
                    <Row>
                      <Col md={12} className='d-flex align-items-center justify-content-center'>
                        <Button variant="light" onClick={() => this.getActivities()} style={{ width: '200px' }}>
                          {t('Load More')}
                        </Button>
                      </Col>
                    </Row>
                  }
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        {/* ACTIVITY MODAL - Ahmad  */}
        <Offcanvas show={activityModal.show} onHide={activityModal.closeModal} style={{ height: '100%' }} placement='bottom'>
          <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
            <div className='d-flex flex-row'>
              <span className='header-back-btn' onClick={activityModal.closeModal} style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[defaultTheme.theme_id].color9} />
              </span>
              <Offcanvas.Title>{t(activityModal.modalTitle)}</Offcanvas.Title>
            </div>
            <div>
              <Button disabled={working} variant='success' onClick={() => this.saveActivity()}>
                {t('Save')} {working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
              </Button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              className={'d-flex flex-row grey-section'}
              style={{
                overflow: 'initial',
                backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                backgroundRepeat: 'repeat',
                backgroundPosition: '10px 12px',
                overflow: 'hidden',
              }}
            >
              <div style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '74vh', overflow: 'auto' }}>
                <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
                  {formSections.map((v, i) => {
                    return (
                      <div key={i}>
                        <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: v.uid == formSectionActive.parent ? brand_colors[defaultTheme.theme_id].color19 : brand_colors[defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                          <FontAwesomeIcon
                            icon={v.completed ? faCheckCircle : faCircle}
                            color={v.uid == formSectionActive.parent ? brand_colors[defaultTheme.theme_id].color20 : v.completed ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color9}
                            style={{
                              fontSize: 12,
                              marginRight: 5,
                              opacity: v.uid == formSectionActive.parent || v.completed ? 1 : 0.7,
                            }}
                          />
                          <span style={{ fontSize: 18, fontWeight: '600', color: v.uid == formSectionActive.parent ? brand_colors[defaultTheme.theme_id].color20 : brand_colors[defaultTheme.theme_id].color9, opacity: v.uid == formSectionActive.parent ? 1 : 0.7 }}>{v.name}</span>
                        </div>

                        <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px brand_colors[defaultTheme.theme_id].color18', marginLeft: '1.5rem' }}>
                          {v.data.map((vv, ii) => {
                            console.log('data.v', vv);

                            return (
                              <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                                <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                                <span style={{ fontSize: 14, color: vv.completed ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18 }}>{vv.name}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '74vh' }}>
                {/* SECTION 1 */}
                {this.state.formSectionActive.parent == '1' && (
                  <div id='form_section_1'>
                    <Row>
                      <Col>
                        {/* aa_name */}
                        <div id='form_section_1.1'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Activity Name')}
                            </Form.Label>
                            <Form.Control type='text' value={academic_activity.aa_name} onChange={(event) => this.setStateAcademicActivity('aa_name', event.target.value)} />
                          </Form.Group>
                        </div>

                        <div className='d-flex flex-row'>
                          {/* aa_desc_cidea */}
                          <div id='form_section_1.2' className='pe-1' style={{ flex: 1 }}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                <img src={imgDescription} />
                                {t('Central Idea')}
                              </Form.Label>
                              <Form.Control as="textarea" rows={3} onChange={(event) => this.setStateAcademicActivity('aa_desc_cidea', event.target.value)} value={academic_activity.aa_desc_cidea} />
                            </Form.Group>
                          </div>
                          {/* aa_desc_unit */}
                          <div id='form_section_1.5' className='ps-1' style={{ flex: 1 }}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                <img src={imgDescription} />
                                {t('Unit')}
                              </Form.Label>
                              <Form.Control as="textarea" rows={3} onChange={(event) => this.setStateAcademicActivity('aa_desc_unit', event.target.value)} value={academic_activity.aa_desc_unit} />
                            </Form.Group>
                          </div>
                        </div>

                      </Col>
                      {/* aa_image */}
                      <Col md='4' id='form_section_1.6'>
                        <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                          <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: brand_colors[defaultTheme.theme_id].color18, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'>
                            {aa_image ? (
                              <img src={isEditing ? aa_image : aa_image.url} alt={t('Preview')} style={{ height: '100%' }} />
                            ) : (
                              <button onClick={() => this.aa_image_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: brand_colors[defaultTheme.theme_id].color18 }} />
                              </button>
                            )}

                            {aa_image && (
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                                onClick={() => {
                                  this.setState({ aa_image: null }, () => this.checkCompletedFormSections());
                                }}
                              />
                            )}
                          </div>
                          <Form.Control className='d-none' ref={this.aa_image_ref} type='file' onChange={this.addActivityAttachment} name='aa_image' />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className='d-flex flex-row'>
                          {/* aa_desc */}
                          <div id='form_section_1.3' className='pe-1' style={{ flex: 1 }}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                <img src={imgDescription} />
                                {t('Activity Design')}
                              </Form.Label>
                              <Form.Control as="textarea" rows={3} onChange={(event) => this.setStateAcademicActivity('aa_desc', event.target.value)} value={academic_activity.aa_desc} />
                            </Form.Group>
                          </div>
                          {/* aa_desc_general */}
                          <div id='form_section_1.4' className='ps-1' style={{ flex: 1 }}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                <img src={imgDescription} />
                                {t('General Description')}
                              </Form.Label>
                              <Form.Control as="textarea" rows={3} onChange={(event) => this.setStateAcademicActivity('aa_desc_general', event.target.value)} value={academic_activity.aa_desc_general} />
                            </Form.Group>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.8'>
                      <span className='heading'>{'Objectives'}</span>
                    </div>
                    <Row>
                      {/* activity curriculum */}
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Program')}</Form.Label>
                          <Select className='custom-select' options={programList} onChange={this.onChangeProgram} value={program} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Age')}</Form.Label>
                          <Select className='custom-select' options={ageList} onChange={this.onChangeAge} value={age} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Area')}</Form.Label>
                          <Select className='custom-select' options={learnareaList} onChange={this.onChangeLearnArea} value={learnarea} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Aspect')}</Form.Label>
                          <Select className='custom-select' options={aspectList} onChange={this.onChangeAspect} value={aspect} />
                        </Form.Group>
                      </Col>
                      <Col md={12} id='form_section_1.7'>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Objective')}</Form.Label>
                          <Select
                            isMulti={true}
                            closeMenuOnSelect={false}
                            options={curriculumList}
                            onChange={(event) => {
                              this.setStateAcademicActivity('curriculum_ids', JSON.stringify(event.map((v) => v.value)));
                            }}
                            value={JSON.parse(academic_activity.curriculum_ids).map((v, i) => {
                              return {
                                value: v, label: assessmentMenuData.allCurriculum.find((vv) => vv.curriculum_id
                                  == v).curriculum_code
                              };
                            })}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          />
                        </Form.Group>

                      </Col>
                    </Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.8'>
                      <span className='heading'>{'Attachments'}</span>
                    </div>
                    <Row>
                      {/* ADD ATTACHMENT */}
                      <Col md='3' className='mb-1'>
                        <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                          <div style={{ height: 100, display: 'flex', overflow: 'hidden', borderStyle: 'dashed', borderColor: brand_colors[defaultTheme.theme_id].color18, borderWidth: 2, borderRadius: 20 }}>
                            <button onClick={() => this.filePicker.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                              <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: brand_colors[defaultTheme.theme_id].color18 }} />
                            </button>
                          </div>
                          <Form.Control className='d-none' ref={this.filePicker} type='file' multiple onChange={this.addActivityAttachment} name='academic_activity_attachments' />
                        </div>
                      </Col>
                      {/* ALL ATTACHMENTS */}
                      {academic_activity_attachments.map((v, i) => {
                        return (
                          <Col key={i} md='3' className='mb-1'>
                            <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                              {/* IMAGE */}
                              {v.data.type.includes('image') && (
                                <>
                                  <img src={v.url} alt={t('Preview')} style={{ height: '100%' }} />
                                </>
                              )}
                              {/* PDF */}
                              {v.data.type.includes('application') && (
                                <>
                                  <img src={pdfAttachment} alt={t('Preview')} style={{ height: '100%' }} />
                                </>
                              )}
                              <button
                                onClick={() => {
                                  academic_activity_attachments.splice(i, 1);
                                  this.setState({ academic_activity_attachments });
                                }}
                                style={{ background: 'none', border: 0 }}
                              >
                                <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color11, position: 'absolute', bottom: 5, right: 5, cursor: 'pointer' }} />
                              </button>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.9'>
                      <span className='heading'>{'Skills'}</span>
                    </div>
                    {/* SKILLS */}
                    <Row>
                      <Col>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Skill')}
                          </Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={2}
                            value={editSkill ? editSkill.aar_title : ''}
                            onChange={(event) => {
                              this.setActivityResource('aar_title', event.target.value, 'skill', editSkill ? editSkill.aar_id : '');
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2} className='pt-4'>
                        <Button
                          className='btn btn-primary btn-icon'
                          onClick={() => {
                            let academic_activity_resources = this.state.academic_activity_resources;
                            academic_activity_resources = academic_activity_resources.map((v, i) => {
                              if (v.editing && v.aar_type == 'skill') {
                                return { ...v, editing: false };
                              }
                              return v;
                            });
                            this.setState({ academic_activity_resources }, () => this.checkCompletedFormSections());
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} style={{ ontSize: 16 }} />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                          {this.state.academic_activity_resources.map((v, i) => {
                            return v.aar_type == 'skill' && !v.editing ? (
                              <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                <span className='title'>{v.aar_title}</span>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  color={brand_colors[defaultTheme.theme_id].color16}
                                  style={{
                                    fontSize: 16,
                                    cursor: 'pointer',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                  }}
                                  onClick={() => {
                                    let academic_activity_resources = this.state.academic_activity_resources;
                                    academic_activity_resources = academic_activity_resources.map((vv, ii) => {
                                      return i == ii ? { ...vv, editing: true } : { ...vv };
                                    });
                                    this.setState({ academic_activity_resources: academic_activity_resources });
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  color={brand_colors[defaultTheme.theme_id].color11}
                                  style={{
                                    fontSize: 16,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: v.aar_id, action: () => this.deleteActivityResources() } })}
                                />
                              </div>
                            ) : null;
                          })}
                        </div>
                      </Col>
                    </Row>

                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.10'>
                      <span className='heading'>{'External Resources'}</span>
                    </div>
                    {/* EXTERNAL RESOURCES */}
                    <Row className='mb-2'>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Title')}
                          </Form.Label>
                          <Form.Control
                            value={editExternalResource ? editExternalResource.aar_title : ''}
                            onChange={(event) => {
                              this.setActivityResource('aar_title', event.target.value, 'external_resource', editExternalResource ? editExternalResource.aar_id : '');
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('URL')}
                          </Form.Label>
                          <Form.Control
                            value={editExternalResource ? editExternalResource.aar_url : ''}
                            onChange={(event) => {
                              this.setActivityResource('aar_url', event.target.value, 'external_resource', editExternalResource ? editExternalResource.aar_id : '');
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mt-3'>
                          <Form.Check
                            type="switch"
                            label={t('Share with Students')}
                            onChange={() => this.setActivityResource('aar_public', editExternalResource ? (editExternalResource.aar_public) ? 0 : 1 : 0, 'external_resource', editExternalResource ? editExternalResource.aar_id : '')}
                            checked={editExternalResource ? editExternalResource.aar_public : 1}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2} className='pt-4'>
                        <Button
                          className='btn btn-primary btn-icon'
                          onClick={() => {
                            let academic_activity_resources = this.state.academic_activity_resources;
                            academic_activity_resources = academic_activity_resources.map((v, i) => {
                              if (v.editing && v.aar_type == 'external_resource') {
                                return { ...v, editing: false };
                              }
                              return v;
                            });
                            this.setState({ academic_activity_resources }, () => this.checkCompletedFormSections());
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} style={{ ontSize: 16 }} />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                          {this.state.academic_activity_resources.map((v, i) => {
                            return v.aar_type == 'external_resource' && !v.editing ? (
                              <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                <div className='d-flex flex-column'>
                                  <div>
                                    <a href={v.aar_url} target='_blank'>{v.aar_title}</a>
                                  </div>
                                  <div className={(v.aar_public == 1) ? 'badge badge-light-success' : 'badge badge-light-warning'}>{(v.aar_public == 1) ? 'Public' : 'Private'}</div>
                                </div>

                                <FontAwesomeIcon
                                  icon={faEdit}
                                  color={brand_colors[defaultTheme.theme_id].color16}
                                  style={{
                                    fontSize: 16,
                                    cursor: 'pointer',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                  }}
                                  onClick={() => {
                                    let academic_activity_resources = this.state.academic_activity_resources;
                                    academic_activity_resources = academic_activity_resources.map((vv, ii) => {
                                      return i == ii ? { ...vv, editing: true } : { ...vv };
                                    });
                                    this.setState({ academic_activity_resources: academic_activity_resources });
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  color={brand_colors[defaultTheme.theme_id].color11}
                                  style={{
                                    fontSize: 16,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: v.aar_id, action: () => this.deleteActivityResources() } })}
                                />
                              </div>
                            ) : null;
                          })}
                        </div>
                      </Col>
                    </Row>



                  </div>
                )}
                {/* SECTION 2 */}
                {this.state.formSectionActive.parent == '2' && (
                  <div id='form_section_2'>
                    {/* Transdisciplinary Theme 2.1*/}
                    <div>
                      <div className={'d-flex flex-row align-items-center section-heading'} id='form_section_2.1'>
                        <span className='heading'>{'International Baccalaureate'}</span>
                      </div>
                      <div>
                        <div className='ms-2' style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.8 }}>{t('Select the transdisciplinary theme for this Activity')}</div>
                        <Row>
                          <Col md={12} className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {this.state.academicLessonAttributes.map((vvv, iii) => {
                              return (vvv.ala_type == 'lesson_focus' && vvv.ala_parent_id == 0) && <div key={iii} className='div-box-theme cursor-pointer' style={{ minWidth: '31%' }} onClick={() => {
                                let academic_activity_resources = this.state.academic_activity_resources
                                // DON'T CHANGE IN CASE THE SAME BOX IS CLICKED
                                if (transdisciplinary_theme && transdisciplinary_theme.aar_type_id != vvv.ala_id) {
                                  // REMOVE ALL CHILDREN
                                  academic_activity_resources = academic_activity_resources.filter((vvvv, iiii) => {
                                    return (vvvv.aar_type != 'transdisciplinary_theme_focus')
                                  })
                                  // THEN CHANGE THE PARENT SELECTION
                                  academic_activity_resources = academic_activity_resources.map((vvvv, iiii) => {
                                    return (vvvv.aar_type == 'transdisciplinary_theme')
                                      ? { ...vvvv, aar_title: vvv.ala_title, aar_type_id: vvv.ala_id }
                                      : { ...vvvv }
                                  })
                                } else {
                                  // ADD NEW PARENT
                                  academic_activity_resources.push({
                                    aar_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                    aar_type: 'transdisciplinary_theme',
                                    aar_title: vvv.ala_title,
                                    aar_desc: '',
                                    aar_type_id: vvv.ala_id,
                                  })
                                }
                                this.setState({ academic_activity_resources }, () => {
                                  this.checkCompletedFormSections()
                                })
                              }}>
                                <div className='check'>
                                  {(transdisciplinary_theme)
                                    ? <FontAwesomeIcon
                                      icon={(transdisciplinary_theme.aar_type_id == vvv.ala_id) ? faCircleDot : faCircle}
                                      color={(transdisciplinary_theme.aar_type_id == vvv.ala_id) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color9}
                                      style={{ fontSize: 14, opacity: (transdisciplinary_theme.aar_type_id == vvv.ala_id) ? 1 : 0.3 }}
                                    /> : null}
                                </div>
                                <div className='d-flex flex-row align-items-center'>
                                  <div className='div_image'>
                                    <img src={portalURL + vvv.center_id + '/academic_lesson_attributes/' + vvv.ala_description_2} className="image" />
                                  </div>
                                  <div className='heading'>{vvv.ala_title}</div>
                                </div>
                              </div>
                            })}
                          </Col>
                        </Row>
                        <Row>
                          {(transdisciplinary_theme)
                            ? <Col md={12}>
                              <div style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.8 }}>{t('Select the focus of transdisciplinary theme.')}</div>
                              <div style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.6 }}>{t('Which parts of the Transdisciplinary Theme will the activity focus on?')}</div>
                            </Col> : null}
                          <Col>
                            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                              {(transdisciplinary_theme) && this.state.academicLessonAttributes.map((vvv, iii) => {
                                let academic_activity_resourcesFind = this.state.academic_activity_resources.find((vvvv, iiii) => {
                                  return (vvvv.aar_type == 'transdisciplinary_theme_focus' && vvvv.aar_type_id == vvv.ala_id)
                                })

                                return (vvv.ala_type == 'lesson_focus' && transdisciplinary_theme.aar_type_id == vvv.ala_parent_id)
                                  ? (<div key={iii} className="d-flex flex-row justify-content-center align-items-center listgroup cursor-pointer" onClick={() => {
                                    let academic_activity_resources = this.state.academic_activity_resources
                                    let academic_activity_resourcesFind = academic_activity_resources.findIndex((vvvv, iiii) => {
                                      return (vvvv.aar_type == 'transdisciplinary_theme_focus' && vvvv.aar_type_id == vvv.ala_id)
                                    })
                                    // IF NOT IN ARRAY ALREADY THEN ADD
                                    if (academic_activity_resourcesFind == -1) {
                                      academic_activity_resources.push({
                                        aar_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                        aar_type: 'transdisciplinary_theme_focus',
                                        aar_title: vvv.ala_title,
                                        aar_desc: '',
                                        aar_type_id: vvv.ala_id,
                                      })
                                    }
                                    //JUST REMOVE FROM ARRAY IF ALREADY EXISTS
                                    else {
                                      academic_activity_resources.splice(academic_activity_resourcesFind, 1);
                                    }
                                    this.setState({ academic_activity_resources }, () => {
                                      this.checkCompletedFormSections()
                                    })
                                  }}>
                                    <FontAwesomeIcon
                                      icon={(academic_activity_resourcesFind) ? faCheckCircle : faCircle}
                                      color={(academic_activity_resourcesFind) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color9}
                                      style={{ fontSize: 16, opacity: (academic_activity_resourcesFind) ? 1 : 0.3 }}
                                    />
                                    <span className='title'>{vvv.ala_title}</span>
                                  </div>) : null
                              })}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    {/* Goals & Success Criteria 2.2*/}
                    <div>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_2.2'>
                        <span className='heading'>{'Goals & Success Criteria'}</span>
                      </div>
                      <Row>
                        <Col md={12}>
                          <Alert variant='secondary' className='mb-1'>
                            <div>{'What is it we want students to know, understand and be able to do'}</div>
                            <div>{'How are learning goals and success criteria co-constructed between teachers and students?'}</div>
                          </Alert>
                        </Col>

                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Goal')}
                            </Form.Label>
                            <Form.Control
                              as='textarea'
                              rows={2}
                              value={editSuccess ? editSuccess.aar_title : ''}
                              onChange={(event) => {
                                this.setActivityResource('aar_title', event.target.value, 'success', editSuccess ? editSuccess.aar_id : '');
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2} className='pt-4'>
                          <Button
                            className='btn btn-primary btn-icon'
                            onClick={() => {
                              let academic_activity_resources = this.state.academic_activity_resources;
                              academic_activity_resources = academic_activity_resources.map((v, i) => {
                                if (v.editing && v.aar_type == 'success') {
                                  return { ...v, editing: false };
                                }
                                return v;
                              });
                              this.setState({ academic_activity_resources }, () => this.checkCompletedFormSections());
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              color={brand_colors[defaultTheme.theme_id].color8}
                              style={{
                                fontSize: 16,
                              }}
                            />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {this.state.academic_activity_resources.map((v, i) => {
                              return v.aar_type == 'success' && !v.editing ? (
                                <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                  <span className='title'>{v.aar_title}</span>
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    color={brand_colors[defaultTheme.theme_id].color16}
                                    style={{
                                      fontSize: 16,
                                      cursor: 'pointer',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                    }}
                                    onClick={() => {
                                      let academic_activity_resources = this.state.academic_activity_resources;
                                      academic_activity_resources = academic_activity_resources.map((vv, ii) => {
                                        return i == ii ? { ...vv, editing: true } : { ...vv };
                                      });
                                      this.setState({ academic_activity_resources: academic_activity_resources });
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    color={brand_colors[defaultTheme.theme_id].color11}
                                    style={{
                                      fontSize: 16,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: v.aar_id, action: () => this.deleteActivityResources() } })}
                                  />
                                </div>
                              ) : null;
                            })}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {/* Lines of Enquiry 2.3*/}
                    <div>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_2.3'>
                        <span className='heading'>{'Key Concepts'}</span>
                      </div>
                      <Row>
                        <Col md={12}>
                          <Alert variant='secondary' >
                            <div>{'It help students to engage in abstract thinking and conceptual thinking to cope-with tricky ideas. Key Concepts can help learners to deal with knowledge in transdisciplinary themes, which the IB intends to encourage. This kind of learning is not limited to factual concepts and content, helping students to engage with broader concepts that can motivate inquisitive, creative and critical thinking.'}</div>
                          </Alert>
                        </Col>
                        <Col md={12} className='d-flex flex-row flex-wrap'>
                          {this.state.academicLessonAttributes.map((vvv, iii) => {
                            let findIndexSelectedData = this.state.academic_activity_resources.findIndex((vvvv) => (vvvv.aar_type_id == vvv.ala_id && vvvv.aar_type == 'lesson_key_concept'))

                            if (vvv.ala_type == 'lesson_key_concept' && vvv.ala_parent_id == 0) {
                              return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                                <div className='heading'>
                                  {vvv.ala_title}
                                </div>
                                <div className='description'>{vvv.ala_description_2}</div>
                                <Form.Group className='mb-3' style={{ width: '100%' }}>
                                  <Form.Label>
                                    <img src={imgDescription} />
                                    {vvv.ala_description_1}
                                  </Form.Label>
                                  <Form.Control as='textarea' rows={3} value={(findIndexSelectedData !== -1) ? this.state.academic_activity_resources[findIndexSelectedData].aar_desc : ''} onChange={(event) => {
                                    let academic_activity_resources = this.state.academic_activity_resources

                                    if (findIndexSelectedData != -1) {
                                      if (event.target.value == '') {
                                        academic_activity_resources.splice(findIndexSelectedData, 1)
                                      } else {
                                        academic_activity_resources[findIndexSelectedData]['aar_desc'] = event.target.value
                                      }
                                    } else {
                                      academic_activity_resources.push({
                                        aar_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                        aar_type: 'lesson_key_concept',
                                        aar_type_id: vvv.ala_id,
                                        aar_title: vvv.ala_title,
                                        aar_desc: event.target.value,
                                      })
                                    }
                                    this.setState({ academic_activity_resources })
                                  }}
                                    onBlur={() => this.checkCompletedFormSections()}
                                  />
                                </Form.Group>
                              </div>
                            }
                          })}
                        </Col>
                      </Row>
                    </div>

                    {/* Lines of Enquiry 2.4*/}
                    <div>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_2.4'>
                        <span className='heading'>{'Lines of Enquiry'}</span>
                      </div>
                      <Row>
                        <Col md={12}>
                          <Alert variant='secondary' className='mb-1'>
                            <div>{'What lines of inquiry will define the scope of inquiry into the central idea?'}</div>
                          </Alert>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Note')}
                            </Form.Label>
                            <Form.Control
                              as='textarea'
                              rows={2}
                              value={editLinesOfEnquiry ? editLinesOfEnquiry.aar_title : ''}
                              onChange={(event) => {
                                this.setActivityResource('aar_title', event.target.value, 'lines_of_enquiry', editLinesOfEnquiry ? editLinesOfEnquiry.aar_id : '');
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2} className='pt-4'>
                          <Button
                            className='btn btn-primary btn-icon'
                            onClick={() => {
                              let academic_activity_resources = this.state.academic_activity_resources;
                              academic_activity_resources = academic_activity_resources.map((v, i) => {
                                if (v.editing && v.aar_type == 'lines_of_enquiry') {
                                  return { ...v, editing: false };
                                }
                                return v;
                              });
                              this.setState({ academic_activity_resources }, () => this.checkCompletedFormSections());
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              color={brand_colors[defaultTheme.theme_id].color8}
                              style={{
                                fontSize: 16,
                              }}
                            />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {this.state.academic_activity_resources.map((v, i) => {
                              return v.aar_type == 'lines_of_enquiry' && !v.editing ? (
                                <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                  <span className='title'>{v.aar_title}</span>
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    color={brand_colors[defaultTheme.theme_id].color16}
                                    style={{
                                      fontSize: 16,
                                      cursor: 'pointer',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                    }}
                                    onClick={() => {
                                      let academic_activity_resources = this.state.academic_activity_resources;
                                      academic_activity_resources = academic_activity_resources.map((vv, ii) => {
                                        return i == ii ? { ...vv, editing: true } : { ...vv };
                                      });
                                      this.setState({ academic_activity_resources: academic_activity_resources });
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    color={brand_colors[defaultTheme.theme_id].color11}
                                    style={{
                                      fontSize: 16,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: v.aar_id, action: () => this.deleteActivityResources() } })}
                                  />
                                </div>
                              ) : null;
                            })}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {/* Approaches to learning 2.5*/}
                    <div>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_2.5'>
                        <span className='heading'>{'Approaches to learning'}</span>
                      </div>
                      <Row>
                        <Col md={12}>
                          <Alert variant='secondary' className='mb-1'>
                            <div>{'The Approaches to Learning in PYP include transdisciplinary themes that are important for students learning both inside and outside the classroom. These are the tools that students use across all subject areas to develop into successful students. Students get the opportunity to practice these skills and reflect on how they apply these skills to their everyday learning.'}</div>
                          </Alert>
                        </Col>
                        <Col md="8">
                          {this.state.academicLessonAttributes.map((vvv, iii) => {
                            if (vvv.ala_type == 'approaches_to_learning' && vvv.ala_parent_id == 0) {
                              let allChildren = this.state.academicLessonAttributes.filter((vvvv, iiii) => vvvv.ala_parent_id == vvv.ala_id)
                              let selectedChildren = []
                              allChildren.map((vvvv, iiii) => {
                                let find = this.state.academic_activity_resources.find((vvvvv, iiiii) => ('approaches_to_learning' == vvvvv.aar_type && vvvv.ala_id == vvvvv.aar_type_id))
                                if (find) {
                                  selectedChildren.push(find)
                                }
                              })

                              return <div className='div-box-theme'>
                                <div className='d-flex flex-row' style={{ cursor: 'pointer' }} onClick={() => this.setState({
                                  addModal: {
                                    ...this.state.addModal, show: true, title: 'Add ' + vvv.ala_title, enum: 'approaches_to_learning', action: () => {
                                      let academic_activity_resources = this.state.academic_activity_resources
                                      allChildren.map((vvvv) => {
                                        let findSelectedIndex = this.state.addModal.selectedData.findIndex((vvvvv) => vvvv.ala_id == vvvvv.aar_type_id)
                                        let findSelectedIndex2 = this.state.academic_activity_resources.findIndex((vvvvv) => ('approaches_to_learning' == vvvvv.aar_type && vvvv.ala_id == vvvvv.aar_type_id))

                                        if (findSelectedIndex !== -1 && findSelectedIndex2 === -1) {
                                          academic_activity_resources.push(this.state.addModal.selectedData[findSelectedIndex])
                                        } else if (findSelectedIndex === -1 && findSelectedIndex2 !== -1) {
                                          academic_activity_resources.splice(findSelectedIndex2, 1)
                                        }
                                      })
                                      this.setState({ academic_activity_resources: academic_activity_resources, addModal: { ...this.state.addModal, show: false } }, () => {
                                        this.checkCompletedFormSections()
                                      })
                                    }, allData: allChildren, selectedData: selectedChildren
                                  }
                                })}>
                                  <div className='div_image'>
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                      style={{ fontSize: 16, }}
                                    />
                                  </div>
                                  <div className='heading'>{vvv.ala_title}</div>
                                </div>
                                <div style={{ fontSize: '13px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.7' }}>{vvv.ala_description_1}</div>
                                {(selectedChildren.length > 0)
                                  && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                                    {selectedChildren.map((vvvv, iiii) => {
                                      return <div className='d-flex flex-row justify-content-between' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                        <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}><span style={{ fontWeight: '600' }}>{vvvv.aar_title}</span> : {vvvv.aar_desc}</div>
                                      </div>
                                    })}
                                  </div>
                                }
                              </div>
                            }
                            return null
                          })}
                        </Col>
                      </Row>
                    </div>


                    {/* Learner Profile 2.6*/}
                    <div>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_2.6'>
                        <span className='heading'>{'Learner Profile'}</span>
                      </div>
                      <Row>
                        <Col md={12}>
                          <Alert variant='secondary' className='mb-1'>
                            <div>{'The aim of all IB programmes is to develop internationally minded people who, recognizing their common humanity and shared guardianship of the planet, help to create a better and more peaceful world. The IB learner profile represents 10 attributes valued by IB World Schools. We believe these attributes, and others like them, can help individuals and groups become responsible members of local, national and global communities.'}</div>
                          </Alert>
                        </Col>
                        <Col md={12} className='d-flex flex-row flex-wrap'>
                          {this.state.academicLessonAttributes.map((vvv, iii) => {
                            let findIndexSelectedData = this.state.academic_activity_resources.findIndex((vvvv) => (vvvv.aar_type_id == vvv.ala_id && vvvv.aar_type == 'learner_profile'))
                            if (vvv.ala_type == 'learner_profile' && vvv.ala_parent_id == 0) {
                              return <div className='div-box-theme cursor-pointer' style={{ flex: 'none', width: '47%' }} onClick={() => {
                                let academic_activity_resources = this.state.academic_activity_resources
                                if (findIndexSelectedData !== -1) {
                                  academic_activity_resources.splice(findIndexSelectedData, 1)
                                } else {
                                  academic_activity_resources.push({
                                    aar_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                    aar_type: 'learner_profile',
                                    aar_type_id: vvv.ala_id,
                                    aar_title: vvv.ala_title,
                                    aar_desc: vvv.ala_description_1,
                                  })
                                }
                                this.setState({ academic_activity_resources: academic_activity_resources }, () => this.checkCompletedFormSections())
                              }}>
                                <div className='check'>
                                  <FontAwesomeIcon
                                    icon={(findIndexSelectedData !== -1) ? faCheckCircle : faCircle}
                                    color={(findIndexSelectedData !== -1) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                                    style={{ fontSize: 14, opacity: (findIndexSelectedData !== -1) ? 1 : 0.3 }}
                                  />
                                </div>
                                <div className='heading'>{vvv.ala_title}</div>
                                <div className='description'>{vvv.ala_description_1}</div>
                              </div>
                            }
                          })}
                        </Col>
                      </Row>
                    </div>

                  </div>
                )}


                {this.state.formSectionActive.parent == '3' && (
                  <div id='form_section_3'>
                    {/* ASSESSMENT */}
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_3.1'>
                      <span className='heading'>{'Assessment'}</span>
                      <Button
                        className='mb-1'
                        variant='success'
                        onClick={() =>
                          questionModal.toggleModal({
                            aa_q_id: null,
                            aa_id: '',
                            aa_q_desc: '',
                            aa_q_order: '',
                            aa_q_file: '',
                            aa_q_type: '',
                            curriculum_ids: JSON.stringify([]),
                            aa_q_audio: '',
                            academic_activity_question_options: [],
                          })
                        }
                      >
                        {' '}
                        {t('Add Question')}
                      </Button>
                    </div>
                    <div className='questions'>
                      <Row>
                        {academic_activity_questions.map((item, index) => (
                          <Col md={6} key={index}>
                            <Card key={index} className='mb-3 q-card'>
                              <Card.Body>
                                <button title={t('Edit')} className='btn-primary btn-icon-primary' onClick={() => this.editQuestion(index)}>
                                  <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color8} style={{ fontSize: 13 }} />
                                </button>
                                <button
                                  className='btn-icon-danger'
                                  title={t('Delete')}
                                  onClick={() =>
                                    this.setState({
                                      deleteModal: {
                                        ...this.state.deleteModal,
                                        show: true,
                                        id: 0,
                                        action: () => {
                                          academic_activity_questions.splice(index, 1);
                                          this.setState({ academic_activity_questions, deleteModal: { ...this.state.deleteModal, show: false } }, () => this.checkCompletedFormSections());
                                        },
                                      },
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimes} color={brand_colors[defaultTheme.theme_id].color8} style={{ fontSize: 13 }} />
                                </button>
                                <div className='q-card-inner'>
                                  <div>
                                    <strong>
                                      <small>{t('Description')}</small>
                                      <Badge style={{ marginLeft: '1rem' }} bg='primary'>
                                        {aa_q_type.find((v, i) => v.value == item.aa_q_type).label}
                                      </Badge>
                                    </strong>
                                    <br />
                                    {trimThisDown(item.aa_q_desc.replace(/(<([^>]+)>)/gi, ' '), 40)}
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                )}

              </Col>
            </div>
          </Offcanvas.Body>
        </Offcanvas >

        <Modal size='xl' show={questionModal.show} onHide={questionModal.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{questionModal.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='custom-styled-form'>
              <Col md={6}>
                <Row style={{ position: 'relative', zIndex: 8 }}>
                  <Col md={6}>
                    <Form.Group className='mb-3' id='div_aa_q_type'>
                      <Form.Label>{t('Type')}</Form.Label>
                      <Select options={aa_q_type} onChange={(event) => this.setStateAcademicActivityQuestion('aa_q_type', event.value)} value={academic_activity_question.aa_q_type ? { value: academic_activity_question.aa_q_type, label: aa_q_type.find((v) => v.value == academic_activity_question.aa_q_type).label } : ''} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className='mb-2'>
                    <CKEditor style={{ height: 200 }} editor={Editor} data={academic_activity_question.aa_q_desc} onChange={(event, editor) => this.setStateAcademicActivityQuestion('aa_q_desc', editor.getData())} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Objective')}</Form.Label>

                      <Badge
                        style={{
                          position: 'relative',
                          right: '-20px',
                          top: '9px',
                          zIndex: 1,
                        }}
                        className='cursor-pointer'
                        bg='primary'
                        onClick={() => this.setState({ filterCurriculum: 'ALL' })}
                      >
                        {t('All')}
                        {this.state.filterCurriculum == 'ALL' && (
                          <span style={{ borderRadius: 100, backgroundColor: brand_colors[defaultTheme.theme_id].color8, padding: 3, position: 'absolute', top: -10, right: -12 }}>
                            <FontAwesomeIcon icon={faCircleCheck} color={brand_colors[defaultTheme.theme_id].color4} style={{ fontSize: 14 }} />
                          </span>
                        )}
                      </Badge>

                      <Badge
                        className='cursor-pointer'
                        style={{
                          marginLeft: '1rem',
                          position: 'relative',
                          right: '-20px',
                          top: '9px',
                          zIndex: 1,
                        }}
                        bg='warning'
                        onClick={() => this.setState({ filterCurriculum: 'LINKED' })}
                      >
                        {t('Activity Linked')}
                        {this.state.filterCurriculum == 'LINKED' && (
                          <span style={{ borderRadius: 100, backgroundColor: brand_colors[defaultTheme.theme_id].color8, padding: 3, position: 'absolute', top: -10, right: -12 }}>
                            <FontAwesomeIcon icon={faCircleCheck} color={brand_colors[defaultTheme.theme_id].color4} style={{ fontSize: 14 }} />
                          </span>
                        )}
                      </Badge>

                      <Select
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={
                          this.state.filterCurriculum == 'ALL'
                            ? curriculums.curriculum_options_grouped
                            : JSON.parse(academic_activity.curriculum_ids).map((v, i) => {
                              return { value: v, label: curriculums.curriculum_options.find((vv) => vv.value == v).label };
                            })
                        }
                        onChange={(event) => {
                          this.setStateAcademicActivityQuestion('curriculum_ids', JSON.stringify(event.map((v) => v.value)));
                        }}
                        value={JSON.parse(academic_activity_question.curriculum_ids).map((v, i) => {
                          return { value: v, label: curriculums.curriculum_options.find((vv) => vv.value == v).label };
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <h6 className='mb-0'>{t('Question Options')}</h6>
                  <Button disabled={['BD', 'DD', 'File', 'Audio'].includes(academic_activity_question.aa_q_type) ? true : false} variant='success' onClick={this.addQuestionOption}>
                    {t('Add Option')}
                  </Button>
                </div>
                {academic_activity_question.academic_activity_question_options.map((item, index) => (
                  <Card className='mb-3 quest-opt-card' key={index}>
                    {(academic_activity_question.aa_q_type == 'MCQ' || academic_activity_question.aa_q_type == 'TF') && (
                      <button className='mark-correct' style={{ backgroundColor: item.aa_q_op_checked == 0 ? brand_colors[defaultTheme.theme_id].color10 : brand_colors[defaultTheme.theme_id].color4 }} onClick={() => this.handleOptionCheckChange(index, 'aa_q_op_checked', true)}>
                        <FontAwesomeIcon
                          icon={item.aa_q_op_checked == 0 ? faCircle : faCircleCheck}
                          color={brand_colors[defaultTheme.theme_id].color8}
                          style={{
                            fontSize: 17,
                          }}
                        />
                        <span className='ms-1'>{t('Mark as correct')}</span>
                      </button>
                    )}
                    {academic_activity_question.aa_q_type == 'MRQ' && (
                      <button
                        className='mark-correct'
                        style={{
                          paddingLeft: 8,
                          backgroundColor: item.aa_q_op_checked == 0 ? brand_colors[defaultTheme.theme_id].color10 : brand_colors[defaultTheme.theme_id].color4,
                        }}
                        onClick={() => this.handleOptionCheckChange(index, 'aa_q_op_checked')}
                      >
                        <FontAwesomeIcon icon={item.aa_q_op_checked == 0 ? faSquare : faSquareCheck} color={brand_colors[defaultTheme.theme_id].color8} style={{ fontSize: 17 }} />
                        <span className='ms-1'>{t('Mark as correct')}</span>
                      </button>
                    )}
                    <button onClick={() => this.deleteOption(index)} className='btn-icon-danger'>
                      <FontAwesomeIcon icon={faTimes} color={brand_colors[defaultTheme.theme_id].color8} style={{ fontSize: 16 }} />
                    </button>
                    <Card.Body>
                      {academic_activity_question.aa_q_type == 'Blanks' ? (
                        <TagsInput value={item.aa_q_op_value} onChange={this.handleOptionTagsChange(index, 'aa_q_op_value')} name='aa_q_op_value' />
                      ) : (
                        <Row>
                          <Col md={academic_activity_question.aa_q_type == 'Match' ? 6 : 12}>
                            {item.aa_q_op_text_picture &&
                              <a target='_blank' href={portalURL + item.center_id + '/academic_activity_question_options/' + item.aa_q_op_text_picture}>{t('Old Attachment')}</a>
                            }

                            {<Form.Control type='file' className='mb-2' name='aa_q_op_text_picture' onChange={this.handleOptionFileChange(index)} />}

                            <Form.Control type='text' name='aa_q_op_text' value={item.aa_q_op_text} onBlur={this.handleOptionTextChange(index)} />
                          </Col>
                          {academic_activity_question.aa_q_type == 'Match' && (
                            <Col md={6} className='ps-0'>
                              {item.aa_q_op_value_picture &&
                                <a target='_blank' href={portalURL + item.center_id + '/academic_activity_question_options/' + item.aa_q_op_value_picture}>{t('Old Attachment')}</a>
                              }
                              <Form.Control type='file' className='mb-2' name='aa_q_op_value_picture' onChange={this.handleOptionFileChange(index)} />
                              <Form.Control type='text' name='aa_q_op_value' value={item.aa_q_op_value} onBlur={this.handleOptionTextChange(index)} />
                            </Col>
                          )}
                        </Row>
                      )}
                    </Card.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={questionModal.closeModal}>
              {t('Cancel')}
            </Button>
            <Button variant='primary' onClick={this.addUpdateQuestion}>
              {t('Save Question')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size='md' show={this.state.addModal.show} onHide={this.state.addModal.onClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.addModal.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.addModal.allData.map((v, i) => {
                let findIndexSelectedData = -1
                if (this.state.addModal.enum == 'approaches_to_learning') {
                  findIndexSelectedData = this.state.addModal.selectedData.findIndex((vv, ii) => vv.aar_type_id == v.ala_id)
                }
                else if (this.state.addModal.enum == 'competency') {
                  findIndexSelectedData = this.state.addModal.selectedData.findIndex((vv, ii) => vv.aar_type_id == v.curriculum_id)
                }

                return <div key={i} className='d-flex flex-row cursor-pointer' style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }} onClick={() => {
                  let selectedData = this.state.addModal.selectedData

                  if (findIndexSelectedData !== -1) {
                    selectedData.splice(findIndexSelectedData, 1)
                  } else {
                    if (this.state.addModal.enum == 'approaches_to_learning') {
                      selectedData.push({
                        aar_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                        aar_type: this.state.addModal.enum,
                        aar_title: v.ala_title,
                        aar_desc: v.ala_description_1,
                        aar_type_id: v.ala_id,
                      })
                    } else if (this.state.addModal.enum == 'competency') {
                      selectedData.push({
                        aar_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                        aar_type: this.state.addModal.enum,
                        aar_title: v.curriculum_code,
                        aar_desc: '',
                        aar_type_id: v.curriculum_id,
                      })
                    }

                  }
                  this.setState({ addModal: { ...this.state.addModal, selectedData: selectedData } })
                }}>
                  <FontAwesomeIcon icon={(findIndexSelectedData !== -1) ? faCheckCircle : faCircle} color={(findIndexSelectedData !== -1) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18} style={{ fontSize: '14px', marginTop: '5px', marginRight: '5px', marginLeft: '5px' }} />
                  {(this.state.addModal.enum == 'approaches_to_learning') && <div><span style={{ fontWeight: '600' }}>{v.ala_title}</span> : {v.ala_description_1}</div>}
                  {(this.state.addModal.enum == 'competency') && <div>{v.curriculum_code}</div>}
                </div>
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.state.addModal.onClose}> {t('Close')} </Button>
            <Button variant='warning' onClick={this.state.addModal.action}> {t('Add Selected')} </Button>
          </Modal.Footer>
        </Modal>

        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>

      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Activities);
