import React, { Component } from 'react';
import { Container, Card, Row, Col, Spinner, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { GET } from '../../api';
import { toast } from 'react-toastify';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';


class Calendar extends Component {

  state = {
    events: null,
    modal: {
      show: false,
      title: '',
      data: ''
    }
  };

  componentDidMount() {
    this.getCalendar();
  }

  getCalendar = async (arg) => {
    if (arg == undefined) {
    } else {
      this.dateStart = arg.start
      this.dateEnd = arg.end
    }
    this.setState({
      events: null,
    });
    const { class_id } = this.props.selectedClass.class;

    let request_params = {
      class_id,
      startDate: moment(this.dateStart).format('YYYY-MM-DD') + ' 00:00:00',
      endDate: moment(this.dateEnd).format('YYYY-MM-DD') + ' 23:59:59',
    };

    try {
      const res = await GET('center-calendar/get-center-calendar', {
        params: {
          ...request_params
        }
      });

      const eventsModified = res.data.map((item) => {
        const doc = new DOMParser().parseFromString(item.body, 'text/html');
        return {
          ...item,
          title: item.title + ' - ' + item.acs_name + ' - ' + doc.body.textContent,
          start: item.start_datetime,
          end: item.end_datetime,
          allDay: (moment(item.start_datetime).format('HH:mm') == '00:00' && moment(item.end_datetime).format('HH:mm') == '23:59') ? true : false
        }
      });

      this.setState({
        events: eventsModified,
      });
    } catch (err) {
      console.log('err', err)
      toast.error('Something went wrong while fetching calendar!');

    }
  };

  eventClick = (e) => {
    this.setState({
      modal: {
        show: true,
        title: e.event.title,
        data: e.event.extendedProps.body
      }
    });
  }

  handleClose = () => {
    this.setState({
      modal: {
        show: false,
        title: '',
        data: ''
      }
    })
  }

  render() {
    // Language Direction
    const { lang_orientation: dir, lang_flag } = this.props.defaultLanguage;
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Calendar')}
              backBtn={true}
              showClassSelector={true}
              classSelectorFunc={[this.getCalendar]} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body className='p-4 position-relative' style={{ overflow: 'hidden' }}>
                  {this.state.events == null && (
                    <div className='loading-overlay'>
                      <span className='mb-1'>
                        <Spinner
                          animation='grow'
                          variant='dark'
                        />
                      </span>
                      {t('Fetching events...')}
                    </div>
                  )}
                  <FullCalendar
                    height={'auto'}
                    plugins={[
                      dayGridPlugin,
                      interactionPlugin,
                      timeGridPlugin,
                      listPlugin
                    ]}
                    headerToolbar={{
                      left: "today prev,next",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    }}
                    initialView='timeGridWeek'
                    eventClick={this.eventClick}
                    datesSet={this.getCalendar}
                    events={this.state.events}
                    locale={dir == 1 ? 'ar-kw' : 'en'}
                    direction={dir == 1 ? 'rtl' : 'ltr'}
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <Modal show={this.state.modal.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modal.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.modal.data }}
            />
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
});

export default connect(mapStateToProps, null)(Calendar);
