import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from '../components/Login/_Login';
import Chats from '../components/_partials/Chats/_Chats'
import Error from '../ErrorPages/_Error';
import { refreshLogin } from '../redux/slices/authSlice';

import { NavigationData } from './NavigationData';
import { t } from '../helpers/translation_helper.js';


/**
 * App Routes
 */
class AppRoutes extends Component {

  componentDidMount() {

    if (this.props.authenticated) {
      this.props.refreshLogin();

      // On refresh set the direction
      document.getElementsByTagName('html')[0].setAttribute('dir', this.props.defaultLanguage.lang_orientation === 1 ? 'rtl' : 'ltr');
    }
  }

  redirectTo = () => {
    const { authenticated, authData } = this.props;

    if (authenticated) {
      if (authData.redirectTo) {
        return <Navigate to={authData.redirectTo} />
      }
      else {
        return <Login />
      }
    } else {
      return <Login />
    }
  }


  render() {
    const { authenticated, authData } = this.props;
    return (
      <div>

        {/* CHATS MODULE */}
        {authenticated &&
          <Chats />
        }

        <Routes>
          <Route path='/' element={this.redirectTo()} />
          {/* NAVTREE: category_name->academic.ieducore.com */}
          {authenticated ? (
            <>
              {['3'].includes(this.props.authData.loginData.role_id)
                ? <Route path={'students'} element={<Navigate to='/students/information' />} />
                : <Route path={'students'} element={<Navigate to='/students/student-information' />} />
              }
              {/* <Route path={'employees'} element={<Navigate to='/employees/list' />} /> */}
              {NavigationData.map((v, i) => {
                let findAllowed = true
                if (authData.loginData.role_id != 22 && authData.allowedNavs) {
                  findAllowed = authData.allowedNavs.find((vv, ii) => vv.link == v.id)
                }
                // DEVELOPMENT
                // findAllowed = true
                if (!findAllowed) {
                  return null
                }
                return <Route key={v.id} path={v.path} element={<React.Suspense
                  fallback={
                    <div id='loader'>
                      <img src={require('../assets/images/iedu-loader.gif')} alt='iEducore' width='120' />
                      {t('Please wait...')}
                      <br />
                      {t('Loading Module')}
                    </div>
                  }>
                  {v.element}
                </React.Suspense>}>
                  {(v.children) && v.children.map((vv, ii) => {
                    let findAllowed2 = true
                    if (authData.loginData.role_id != 22 && authData.allowedNavs) {
                      findAllowed2 = authData.allowedNavs.find((vvv, ii) => vvv.link == vv.id)
                    }
                    console.log('findAllowed2', findAllowed2, vv.id);
                    // DEVELOPMENT
                    // findAllowed2 = true
                    if (!findAllowed2) {
                      return null
                    }
                    return <Route key={ii} path={v.path + '/' + vv.path} element={
                      <React.Suspense
                        fallback={
                          <div id='loader'>
                            <img src={require('../assets/images/iedu-loader.gif')} alt='iEducore' width='120' />
                            {t('Please wait...')}
                            <br />
                            {t('Loading Module')}
                          </div>
                        }>
                        {vv.element}
                      </React.Suspense>
                    } />
                  })}
                </Route>
              })}

              <Route path={'*'} element={<Error NavigationData={NavigationData} />} />
            </>
          ) : (
            <Route path='*' element={<Navigate to='/' />} />
          )}
        </Routes>
        {/* <div style={{ height: '100vh', width: '100vw', position: 'fixed', backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', zIndex: 99 }}>
          <img src={require('../assets/images/iedu-loader.gif')} alt='iEducore' width='120' />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  authData: state.auth.authData,
  languages: state.language,
  defaultLanguage: state.language.defaultLanguage,
});

const mapDispatchToProps = () => ({
  refreshLogin
});

export default connect(mapStateToProps, mapDispatchToProps())(AppRoutes);