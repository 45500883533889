import React, { Component } from 'react';
import { Button, Col, Row, ListGroup, Modal, Form, Badge } from 'react-bootstrap';
import ClassSelector from './ClassSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faBell, faSearch, faLanguage, faCheckCircle, faArrowAltCircleLeft, faArrowAltCircleRight, faUsers, faPalette, faCommentAlt, faRefresh, } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { unsetAuthentication } from '../../../redux/slices/authSlice';
import { setSelectedClass } from '../../../redux/slices/selectedClassSlice';
import { setDefaultLanguage } from '../../../redux/slices/langSlice';
import { updateNotificationsCount } from '../../../redux/slices/generalSlice';
import { setDefaultTheme } from '../../../redux/slices/themeSlice';
import { t } from '../../../helpers/translation_helper';
import {
  brand_colors
} from '../../../helpers/brand_colors_helper';
import { themes } from '../../../constants/themes';
import SweetAlert from 'react-bootstrap-sweetalert';
import { setToggleChat } from '../../../redux/slices/chatsSlice';
import CenterSelector from './CenterSelector';
import SubjectSelector from './SubjectSelector';
import { refreshLangLabels, setLanguages } from '../../../redux/slices/langSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { NavigationData } from '../../../routes/NavigationData';
import Notifications from '../Notifications/_Notifications';
import { GET } from '../../../api';
// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;

/**
 * Header Partial Component
 */
class Header extends Component {
  constructor(props) {
    super(props);

    // State
    this.state = {
      notificationModal: false,
      languageModal: false,
      themeModal: false,
      logoutModal: { show: false, action: () => this.props.unsetAuthentication(), toggleModal: () => this.setState({ logoutModal: { ...this.state.logoutModal, show: !this.state.logoutModal.show } }) },
      searchNavModel: {
        show: false,
        search: '',
        toggle: () => this.setState({ searchNavModel: { ...this.state.searchNavModel, show: !this.state.searchNavModel.show, search: '' } })
      },
      notifications: 0
    };
  }

  componentDidMount() {
    const { defaultTheme } = this.props;
    document.getElementsByTagName('body')[0].setAttribute('class', defaultTheme.theme_id);

    let dashboardsURLS = ['/admin/dashboard/main',
      '/admin/dashboard/other',
      '/dashboard',
      '/admin/dashboard/hr',]
    if (dashboardsURLS.includes(window.location.pathname)) {
      this.getNotifications();
    }
  }

  /**
   * Handle Modal
   * @param {string} modal - Modal name
   */
  handleModal = (modal) => {
    this.setState({
      [modal]: !this.state[modal],
    });

  };

  /**
   * Languages List
   * @returns {HTMLElement} Outputs the languages list in UL LI
   */
  languagesList = () => {
    const { languages, defaultLanguage, defaultTheme } = this.props;

    const langList = languages.map((item, index) => {
      const flag = 'me-1 fi fi-' + item.lang_flag.toLowerCase();
      return (
        <ListGroup.Item
          key={index}
          action
          className='px-2'
          onClick={() => {
            this.props.setDefaultLanguage(item);
            this.handleModal('languageModal');
          }}>
          <Row>
            <Col>
              <span className={flag}></span> {item.lang_name}
            </Col>
            <Col
              className='d-flex align-items-center justify-content-end'
              style={{ fontSize: '20px' }}>
              {defaultLanguage.lang_id == item.lang_id && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color={brand_colors[defaultTheme.theme_id].color4}
                />
              )}
            </Col>
          </Row>
        </ListGroup.Item>
      );
    });

    return langList;
  };


  themeList = () => {
    const { defaultTheme } = this.props;

    const themesList = themes.map((item, index) => {
      return (
        <ListGroup.Item
          key={index}
          action
          className='px-2'
          onClick={() => {
            this.props.setDefaultTheme(item);
            this.handleModal('themeModal');
          }}>
          <Row>
            <Col>
              {item.theme_name}
            </Col>
            <Col
              className='d-flex align-items-center justify-content-end'
              style={{ fontSize: '20px' }}>
              {defaultTheme.theme_name == item.theme_name && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color={brand_colors[defaultTheme.theme_id].color4}
                />
              )}
            </Col>
          </Row>
        </ListGroup.Item>
      );
    });

    return themesList;
  };

  searchNavList = () => {
    let search = this.state.searchNavModel.search.toLowerCase()
    let { allowedNavs, loginData } = this.props.authData
    let no = 0

    return NavigationData.map((v, i) => {
      let findAllowed = true
      if (loginData.role_id != 22 && allowedNavs) {
        findAllowed = allowedNavs.find((vv, ii) => vv.link == v.id)
      }
      // DEVELOPMENT
      // findAllowed = true
      if (!findAllowed) {
        return null
      }
      if (search) {
        if (!((v.name).toLowerCase()).includes(search) && !((v.path).toLowerCase()).includes(search)) {
          if (v.children) {
            let findChildren = v.children.filter((vv, ii) => {
              if (!((vv.name).toLowerCase()).includes(search) && !((vv.path).toLowerCase()).includes(search)) {
                return false
              } else {
                return vv
              }
            })
            if (findChildren.length <= 0) {
              return null
            }
          } else {
            return null
          }
        }
      }
      no++

      return <div key={i}>
        <ListGroup.Item action className='px-2' onClick={() => { }}>
          <NavLink
            style={{ textDecoration: 'none', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.8' }}
            to={v.path}>
            <Row>
              <Col className='d-flex flex-row'>
                <div className='mx-1'>{no}.</div>
                <div>{v.name}</div>
              </Col>
            </Row>
          </NavLink>
        </ListGroup.Item>


        {(v.children) && v.children.map((vv, ii) => {
          let findAllowed2 = true
          if (loginData.role_id != 22 && allowedNavs) {
            findAllowed2 = allowedNavs.find((vvv, ii) => vvv.link == vv.id)
          }
          // DEVELOPMENT
          // findAllowed2 = true
          if (!findAllowed2) {
            return null
          }
          if (search && !((vv.name).toLowerCase()).includes(search) && !((vv.path).toLowerCase()).includes(search)) {
            return null
          }
          no++
          return <ListGroup.Item key={ii} action className='px-2' onClick={() => { }}>
            <NavLink
              style={{ textDecoration: 'none', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.8' }}
              to={vv.path}>
              <Row>
                <Col className='d-flex flex-row'>
                  <div className='mx-1'>{no}.</div>
                  <div>{v.name} {' > '} {vv.name}</div>
                </Col>
              </Row>
            </NavLink>
          </ListGroup.Item>
        })}
      </div>
    })
  };

  refreshLanguagesData = () => {
    this.props.refreshLangLabels(this.state.langOffset)
    axios.get(apiURL + 'language/get-languages').then((res) => {
      if (res.data.type) {
        this.props.setLanguages(res.data.data);
        toast.success(t('Languages Data has been updated'));
      }
    });
  }

  getNotifications = async () => {
    try {
      const res = await GET('notification/get', {
        params: {
          only_count: 1
        }
      });
      this.props.updateNotificationsCount(res.data.data)
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Render Component
   * @returns {HTMLelement}
   */
  render() {
    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage;

    // State
    const { notificationModal, languageModal, themeModal, searchNavModel } = this.state;

    // Props
    const { lite, heading, defaultTheme, authData } = this.props;
    let center_tags = JSON.parse(authData.loginData.center_tags)

    // let headerBG = '';
    // Header BG Image
    // if (authData.stdbanners) {
    // } else {
    //   headerBG = 'url(' + portalURL + authData.userData.center_id + '/employees/' + authData.userData.employee_cover_picture + ')';
    // }
    // if (lite) {
    //   headerBG = null;
    // }

    return (
      <>
        <header
          id='iedu-header'
          className={lite ? 'pt-3 lite-header' : 'pt-3'}
          style={{
            // backgroundImage: headerBG,
            height: lite ? '' : 93,
          }}>
          <Row>
            <Col md="auto" className='d-flex align-items-center'>
              {heading ? (
                <>
                  {this.props.backBtn && (
                    <span className='header-back-btn' onClick={() => window.history.back()}>
                      <FontAwesomeIcon icon={dir == 1 ? faArrowAltCircleRight : faArrowAltCircleLeft} color={brand_colors[defaultTheme.theme_id].color9} />
                    </span>
                  )}
                  <h4 className={dir == 1 ? 'mb-0 pe-2' : 'mb-0 ps-2'}>
                    {heading}
                  </h4>
                </>
              ) : (
                <>
                  {center_tags.length > 1
                    ? <CenterSelector func={this.props.centerSelectorFunc} />
                    : <ClassSelector func={this.props.classSelectorFunc} />}
                </>
              )}
            </Col>
            <Col>

              <div className='d-flex flex-row justify-content-end'>

                {this.props.showClassSelector && <ClassSelector func={this.props.classSelectorFunc} minimal={true} />}
                {this.props.showSubjectSelector && <SubjectSelector func={this.props.subjectSelectorFunc} />}
                {this.props.showCenterSelector && center_tags.length > 1 && <CenterSelector func={this.props.centerSelectorFunc} minimal={true} />}


                <Button
                  onClick={() =>
                    searchNavModel.toggle()
                  }
                  title={t('Search Navigation')}
                  className='btn-header ms-2'
                  variant='info'>
                  <FontAwesomeIcon
                    icon={faSearch}
                    color={brand_colors[defaultTheme.theme_id].color8}
                  />
                </Button>

                <Button onClick={() => this.props.setToggleChat()}
                  style={{ position: 'relative' }}
                  className='btn-header ms-2'
                  variant='success'>
                  <FontAwesomeIcon icon={faCommentAlt} color='#ffffff' />
                  <div style={{ position: 'absolute', top: -5, right: -5 }}>
                    {this.props.chatsCount > 0 && (
                      <div style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color11, color: brand_colors[defaultTheme.theme_id].color8, borderRadius: 100, width: 20, height: 20, fontWeight: 'bold', fontSize: 11, padding: 2 }}>{this.props.chatsCount}</div>
                    )}
                  </div>
                </Button>

                <Button onClick={() => this.handleModal('notificationModal')}
                  style={{ position: 'relative' }}
                  className='btn-header ms-2'
                  variant='secondary'>
                  <FontAwesomeIcon icon={faBell} color={brand_colors[defaultTheme.theme_id].color8} />
                  <div style={{ position: 'absolute', top: -5, right: -5 }}>
                    {this.props.notificationsCount > 0 && (
                      <div style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color11, color: brand_colors[defaultTheme.theme_id].color8, borderRadius: 100, width: 20, height: 20, fontWeight: 'bold', fontSize: 11, padding: 2 }}>{this.props.notificationsCount}</div>
                    )}
                  </div>
                </Button>
                <Button onClick={() => this.handleModal('languageModal')}
                  title={t('Language')}
                  className='btn-header ms-2'
                  variant='secondary'>
                  <FontAwesomeIcon icon={faLanguage} color={brand_colors[defaultTheme.theme_id].color8} />
                </Button>
                <Button onClick={() => this.handleModal('themeModal')}
                  title={t('Theme')}
                  className='btn-header ms-2'
                  variant='secondary'>
                  <FontAwesomeIcon icon={faPalette} color={brand_colors[defaultTheme.theme_id].color8} />
                </Button>
                <Button onClick={() => this.state.logoutModal.toggleModal()}
                  title={t('LOGOUT')}
                  className='btn-header ms-2'>
                  <FontAwesomeIcon icon={faPowerOff} color={brand_colors[defaultTheme.theme_id].color8} />
                </Button>
              </div>
            </Col>
          </Row>
        </header>

        {/* Notifications */}
        <Notifications
          notificationModal={notificationModal}
          handleModal={this.handleModal} />
        <Modal
          size='sm'
          show={languageModal}
          onHide={() => this.handleModal('languageModal')}>
          <Modal.Header>
            <div className='d-flex flex-row align-items-center justify-content-between' style={{ width: '100%' }}>
              <Modal.Title>{t('Select Language')}</Modal.Title>
              <FontAwesomeIcon className='cursor-pointer' style={{ fontSize: 22 }} icon={faRefresh} color={brand_colors[defaultTheme.theme_id].color2} title={t('Refresh Languages Data')} onClick={() => this.refreshLanguagesData()} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <ListGroup variant='flush'>
              {this.languagesList()}
            </ListGroup>
          </Modal.Body>
        </Modal>
        <Modal
          size='sm'
          show={themeModal}
          onHide={() => this.handleModal('themeModal')}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Select Theme')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup variant='flush'>
              {this.themeList()}
            </ListGroup>
          </Modal.Body>
        </Modal>

        <Modal
          show={searchNavModel.show}
          onHide={() => searchNavModel.toggle()}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Search Navigation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-1'>
              <Form.Control type='text' placeholder={t('Search here...')} value={searchNavModel.search} onChange={(e) => this.setState({ searchNavModel: { ...searchNavModel, search: e.target.value } })} />
            </div>
            <ListGroup variant='flush'>
              {this.searchNavList()}
            </ListGroup>
          </Modal.Body>
        </Modal>


        <SweetAlert
          show={this.state.logoutModal.show}
          warning
          showCancel
          confirmBtnText={t('LOGOUT!')}
          cancelBtnText={t('Keep Logged-in!')}
          confirmBtnBsStyle='danger'
          title={(this.state.logoutModal.title) ? this.state.logoutModal.title : t('Confirm logout?')}
          onConfirm={this.state.logoutModal.action}
          onCancel={() => this.state.logoutModal.toggleModal()}
          focusCancelBtn>
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedCLass: state.selectedClass.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  chatsCount: state.chats.chatsCount,
  notificationsCount: state.generalData.notificationsCount,
});

const mapDispatchToProps = () => ({
  unsetAuthentication,
  setSelectedClass,
  setDefaultLanguage,
  setDefaultTheme,
  setToggleChat,
  refreshLangLabels,
  setLanguages,
  updateNotificationsCount
});

export default connect(mapStateToProps, mapDispatchToProps())(Header);
